import React, { useState } from 'react'
import names from './data/index.js'

const App = () => {
  const [content, setContent] = useState('BLACK LIVES MATTER')

  const sayMyName = () =>
    names.forEach((name, i) =>
      setTimeout(() => setContent(name), 300 * (i + 1))
    )

  return (
    <h1
      onMouseOver={sayMyName}
      onMouseLeave={() => setContent('BLACK LIVES MATTER')}
    >
      {content}
    </h1>
  )
}

export default App
